<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ຊື່ກຸ່ມອຸປະກອນ :"
              prop="appl_g_name"
              v-model="form.appl_g_name"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <CInput label="ລາຍລະອຽດ :" prop="appl_g_remark" v-model="form.appl_g_remark" />
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="warning"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "ApplGroupFormUpdate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    appGroup: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        appl_g_name: this.appGroup.appl_g_name,
        appl_g_remark: this.appGroup.appl_g_remark,
      };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        id: this.appGroup.appl_g_id,
        appl_g_name: this.form.appl_g_name,
        appl_g_remark: this.form.appl_g_remark,
      });
    },
  },
};
</script>